// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-mdx": () => import("./../../src/pages/donate.mdx" /* webpackChunkName: "component---src-pages-donate-mdx" */),
  "component---src-pages-recommended-index-js": () => import("./../../src/pages/recommended/index.js" /* webpackChunkName: "component---src-pages-recommended-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-template-js": () => import("./../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-stories-template-js": () => import("./../../src/templates/stories-template.js" /* webpackChunkName: "component---src-templates-stories-template-js" */),
  "component---src-templates-topic-stories-template-js": () => import("./../../src/templates/topic-stories-template.js" /* webpackChunkName: "component---src-templates-topic-stories-template-js" */),
  "component---src-templates-topic-template-js": () => import("./../../src/templates/topic-template.js" /* webpackChunkName: "component---src-templates-topic-template-js" */),
  "component---src-templates-topic-tools-template-js": () => import("./../../src/templates/topic-tools-template.js" /* webpackChunkName: "component---src-templates-topic-tools-template-js" */),
  "component---src-templates-topic-videos-template-js": () => import("./../../src/templates/topic-videos-template.js" /* webpackChunkName: "component---src-templates-topic-videos-template-js" */),
  "component---src-templates-topics-template-js": () => import("./../../src/templates/topics-template.js" /* webpackChunkName: "component---src-templates-topics-template-js" */),
  "component---src-templates-videos-page-js": () => import("./../../src/templates/videos-page.js" /* webpackChunkName: "component---src-templates-videos-page-js" */)
}

