module.exports = [{
      plugin: require('../node_modules/gatsby-remark-markmap/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"quality":90,"withWebp":true,"maxWidth":1920,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"rehypePlugins":[null],"defaultLayouts":{"default":"/opt/build/repo/src/templates/page-template.js"},"gatsbyRemarkPlugins":["gatsby-remark-katex",{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"quality":90,"withWebp":true,"maxWidth":1920,"showCaptions":true}},{"resolve":"gatsby-remark-markmap","options":{"markmap":{},"plugins":["mathJax","prism"]}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener"}},{"resolve":"@weknow/gatsby-remark-twitter","options":{"align":"center"}},"gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-embed-video","options":{"width":800,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true}},{"resolve":"gatsby-remark-responsive-iframe"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134618486-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jason Li","short_name":"Jason","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"static/img/site/brand/icon-64x64.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f7601b689168495247325304fa24d32d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00d1b2","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"www.aiartiste.com","identity":{"github":"jasonshere","twitter":"JasonLi45756873"},"mentions":true,"pingbacks":false,"domain":"www.aiartiste.com","fetchLimit":10000,"token":"4CJsEvg_NgQum_IYnen_zQ"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
